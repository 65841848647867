import { useJumpTo } from './useJumpTo';

const enableChapterJumps = () => {
  const { jumpTo } = useJumpTo();

  const timestamps = Array.from(document.querySelectorAll('.episode-timestamp'));

  if (timestamps) {
    for (const timestamp of timestamps) {
      const time = timestamp.getAttribute('data-timestamp');
      if (!time) continue;

      timestamp.addEventListener('click', () => {
        jumpTo(Number.parseInt(time, 10));
      });
    }
  }
};

export const useEnableChapterJumps = () => {
  return { enableChapterJumps };
};
