const jumpTo = (time) => {
  const audio = document.querySelector('audio');
  if (!audio) return;

  audio.currentTime = time;
};

export const useJumpTo = () => {
  return { jumpTo };
};
